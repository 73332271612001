import React from 'react';
import ReactDOM from "react-dom/client";
import App from './app/App';
import reportWebVitals from './reportWebVitals';

import {ThemeProvider, createTheme} from '@mui/material/styles';
import {CssBaseline} from "@mui/material";

const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    // backgroundColor: 'rgba(236,236,236,0.71)',
                    backgroundColor: 'rgba(219,227,253,0.66)',
                    // backgroundColor: '#deb887',
                    // backgroundImage: [
                    //     'linear-gradient(90deg, rgba(222, 184, 135, 0.4) 50%, rgba(139, 69, 19, 0.4))',
                    //     'linear-gradient(90deg, rgba(222, 184, 135, 0.2) 50%, transparent)',
                    //     'linear-gradient(90deg, rgba(139, 69, 19, 0.2) 70%, transparent)'
                    // ],
                    // backgroundSize: ['50px 100%', '100px 100%', '150px 100%'],
                    // minHeight: '100vh',
                    // minWidth: '100vw',
                    // border: '2px solid #8B4513',
                    // boxShadow: '0 8px 6px -6px black',
                },
                hr: {
                    backgroundColor: 'rgba(0, 0, 0, 0.12)', // The CSS for the divider
                    height: '1px',
                    border: 'none',
                    marginBottom: '0.5rem',
                    marginTop: '0.5rem',
                },
                h1: {
                    color: '#333',
                    marginBottom: '1rem',
                    fontFamily: 'coolvetica'
                },
                h2: {
                    color: '#333',
                    marginBottom: '1rem',
                    fontFamily: 'coolvetica'
                },
                h3: {
                    color: '#333',
                    marginBottom: '1rem',
                    fontFamily: 'coolvetica'
                },
                h4: {
                    color: '#333',
                    marginBottom: '1rem',
                    fontFamily: 'coolvetica'
                },
                h5: {
                    color: '#333',
                    marginBottom: '1rem',
                    fontFamily: 'coolvetica'
                },
                h6: {
                    color: '#333',
                    marginBottom: '1rem',
                    fontFamily: 'coolvetica'
                },
                h7: {
                    color: '#333',
                    marginBottom: '1rem',
                    fontFamily: 'coolvetica'
                },
                h8: {
                    color: '#333',
                    marginBottom: '1rem',
                    fontFamily: 'coolvetica'
                },
                h9: {
                    color: '#333',
                    marginBottom: '1rem',
                    fontFamily: 'coolvetica'
                },
                p: {
                    color: '#00000099',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    fontFamily: 'coolvetica',
                },
                a: {
                    fontSize: 18,
                    color: '#007bff',
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                    fontFamily: 'coolvetica'
                },
            },
        },
        MuiTypography: {
            styleOverrides: {},
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    margin: 2,
                    marginTop: 10,
                    marginBottom: 8,
                    fontFamily: 'coolvetica'
                },
            },
        },
        MuiCardMedia: {
            styleOverrides: {
                root: {
                    width: '100%',
                    height: '100%',
                    borderRadius: 60,
                    objectFit: 'cover'
                }
            }
        },

        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderRadius: 60,
                    overflowY: 'auto',
                    backgroundColor: '#f3f4f6',
                    boxShadow: 'rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) ' +
                        '0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;',
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    margin: 'auto',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'coolvetica',
                    backgroundColor: '#f3f4f6', // Light grey background
                    transition: 'transform .2s', // Animation
                    '&:hover': {
                        transform: 'scale(1.03)', // Hover effect
                    },

                    raised: true,
                    overflow: 'visible',
                    borderRadius: 60,
                    minWidth: 250,
                    maxWidth: '100%',
                    width: 'auto',
                    boxShadow: 'rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) ' +
                        '0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;',
                    '@media (max-width:600px)': {
                        width: '90vw',
                    },
                    '@media (min-width:600px)': {
                        width: '90vw',
                    },
                    '@media (min-width:960px)': {
                        width: '90vw',
                    },
                    '@media (min-width:1280px)': {
                        width: '90vw',
                    },
                    '@media (min-width:1920px)': {
                        width: '90vw',
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    alignSelf: 'center',
                    borderRadius: 60,
                    maxWidth: '100:%',
                    marginBottom: 25,
                    marginTop: 10
                }
            }
        }
    },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        <App/>
    </ThemeProvider>
    // </React.StrictMode>
);

reportWebVitals();
