import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {Grid, Button, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/system';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import InfoIcon from '@mui/icons-material/Info';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useLocation, useNavigate} from 'react-router-dom';

export default function FooterBar({currentUser, signOutOfAccount}) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const imgStyles = {
        maxHeight: 120,
        maxWidth: 200,
        minHeight: 80,
        minWidth: 100
    }

    const FooterTitleDisplay = () => {
        let logoSrc = isMobile
            ? `${process.env.PUBLIC_URL}/logo-white.svg`
            : `${process.env.PUBLIC_URL}/logoNameWhite.svg`;

        return (
            <div onClick={scrollToTop}>
                <img
                    id="site-logo-name"
                    src={logoSrc}
                    alt="KeyWard.com logo"
                    style={imgStyles}
                />
            </div>
        );
    };

    const NavItems = [
        {path: "/", label: "Home", icon: <HomeIcon/>},
        {path: "/learn", label: "Learn", icon: <SchoolIcon/>},
        {path: "/the-crypto-story", label: "The Crypto Story", icon: <InfoIcon/>},
        {path: "/account", label: "My Account", icon: <AccountCircleIcon/>},
    ].filter(Boolean);

    const legalItems = [
        {path: "/TermsAndConditions", label: "Terms & Conditions"},
        {path: "/PrivacyPolicy", label: "Privacy Policy"},
    ].filter(Boolean);

    let navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (item) => {
        if(item.path === location.pathname) {
            scrollToTop();
        } else {
            navigate(item.path);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Grid container direction="column" alignItems="center">
                        <Grid item>
                            {FooterTitleDisplay()}
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="center" spacing={2}>
                                {NavItems.map((item, idx) => (
                                    <Grid item key={idx}>
                                        <Button
                                            variant={'outlined'}
                                            color="inherit"
                                            // startIcon={item.icon}
                                            // component={Link}
                                            to={item.path}
                                            onClick={() => handleNavigation(item)}
                                        >
                                            {item.label}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" spacing={2}>
                                    {legalItems.map((item, idx) => (
                                        <Grid item key={idx}>
                                            <Button
                                                variant={'outlined'}
                                                color="inherit"
                                                // startIcon={item.icon}
                                                // component={Link}
                                                to={item.path}
                                                onClick={() => handleNavigation(item)}
                                            >
                                                {item.label}
                                            </Button>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" >
                                    &copy; {new Date().getFullYear()} KeyWard.com
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
