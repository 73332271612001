import * as  React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import MenuList from "./MenuList";
import {Button, Grid, useMediaQuery} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {useTheme} from "@mui/system";

export default function MenuBar({currentUser, pointBalance, viewModals, setViewModals,
                                    menuDrawerAnchor, menuButtonAnchor, signOutOfAccount, updateErrorsList}) {


    let navigate = useNavigate();

    const handleSignInModalOpen = () => {
        try {
            setViewModals({...viewModals, signInModal: true})
        } catch (error) {
            console.log("error handleSignInModal", error)
            updateErrorsList(error)
        }
    }

    const signInButton = () => {
        try {
            if (!currentUser) {
                return <Button id={"sign-in"} variant="outlined" onClick={handleSignInModalOpen} color="inherit">Sign In / Sign
                    Up</Button>
            }
        } catch (error) {
            console.log("error 1 signInButton", error);
            updateErrorsList(error)
        }
    }

    const pointsDisplay = () => {
        try {
            if (currentUser) {
                return <Button id="points-button" variant="outlined" onClick={() => navigate('/points')} color="inherit">
                    {`${pointBalance.toLocaleString()} WARD`}
                </Button>
            }
        } catch (error) {
            console.log("error pointsDisplay", error);
            updateErrorsList(error)
        }
    }

    const imgStyles = {
            maxHeight: 120,
            maxWidth: 200,
            minHeight: 80,
            minWidth: 100
    }

    const TitleDisplay = () => {
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' is typically <= 600px

        try {
            let logoSrc = isMobile
                ? `${process.env.PUBLIC_URL}/logo-white.svg`
                : `${process.env.PUBLIC_URL}/logoNameWhite.svg`;

            return (
                <img
                    id="site-logo-name"
                    src={logoSrc}
                    alt="KeyWard.com logo"
                    style={imgStyles}
                />
            );
        } catch (error) {
            console.log("error TitleDisplay", error);
            // updateErrorsList(error); // Assuming you have a method to handle errors
            return null;  // Return null or some fallback UI
        }
    }


    const menuListDisplay = () => {
        try {
            return <MenuList currentUser={currentUser} signOutOfAccount={signOutOfAccount}
                             menuDrawerAnchor={menuDrawerAnchor}
                             updateErrorsList={updateErrorsList}
            />
        } catch (error) {
            console.log("error menuListDisplay", error);
            updateErrorsList(error)
        }
    }

    const appBar = () => {
        try {
            if (menuButtonAnchor === 'left') {
                return (<AppBar aria-label="appbar-left" id="appbar-left" position="static">
                        <Toolbar>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    {menuListDisplay()}
                                </Grid>
                                <Grid item>
                                    {TitleDisplay()}
                                </Grid>
                                <Grid item>
                                    {signInButton()}
                                    {pointsDisplay()}
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
            )
            } else if (menuButtonAnchor === 'right') {
                return (
                    <AppBar aria-label="appbar-right" id="appbar-right" position="static">
                        <Toolbar>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    {signInButton()}
                                </Grid>
                                <Grid item>
                                    {TitleDisplay()}
                                </Grid>
                                <Grid item>
                                    {pointsDisplay()}
                                    {menuListDisplay()}
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                )
            } else {
                return (
                    <AppBar aria-label="appbar-default" id="appbar-default" position="static">
                        <Toolbar>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    {menuListDisplay()}
                                </Grid>
                                <Grid item>
                                    {TitleDisplay()}
                                </Grid>
                                <Grid item>
                                    {signInButton()}
                                    {pointsDisplay()}
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                )
            }
        } catch (error) {
            console.log("error appbar", error)
        }
    }
    return (appBar());
}
