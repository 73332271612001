import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SchoolIcon from '@mui/icons-material/School';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import HomeIcon from '@mui/icons-material/Home';
import BookIcon from '@mui/icons-material/Book';
import {Link} from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';

export default function MenuList({currentUser, signOutOfAccount, menuDrawerAnchor, updateErrorsList}) {

    const [navOpen, setNavOpen] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        try {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            setNavOpen({...navOpen, [anchor]: open});
        } catch (error) {
            console.log("error toggleDrawer", error)
        }
    };

    const handleSignOutOfAccount = () => {
        signOutOfAccount()
    }

    const signOutButton = () => {
        try {
            if (currentUser) {
                return <ListItem id="sign-out-list" button
                                 aria-label="sign out"
                                 component={Link}
                                 to={"/"}
                                 onClick={handleSignOutOfAccount}
                                 key={"Sign Out"}>
                    <ListItemIcon id="sign-out-icon-list">
                        <LogoutIcon id="sign-out-icon"/>
                    </ListItemIcon>
                    <ListItemText primary={"Sign Out"}/>
                </ListItem>
            }
        } catch (error) {
            console.log("error signOutButton", error);
            updateErrorsList(error)
        }
    }

    const list = () => (
        <List>
            <ListItem id="home-list"
                      button
                      component={Link}
                      to={"/"}
                      aria-label="Home"
                      key={"Home"}>
                <ListItemIcon id="home-list-icon">
                    <HomeIcon id="home-icon"/>
                </ListItemIcon>
                <ListItemText id="home-icon-text" primary={"Home"}/>
            </ListItem>
            <ListItem id="the-crypto-story-list"
                      button
                      component={Link}
                      to={"/the-crypto-story"}
                      aria-label="The Crypto Story"
                      key={"The Crypto Story"}>
                <ListItemIcon id="book-list-icon">
                    <BookIcon id="book-icon"/>
                </ListItemIcon>
                <ListItemText id="book-icon-text" primary={"The Crypto Story"}/>
            </ListItem>
            <ListItem id="learn-list"
                      button
                      component={Link}
                      to={"/learn"}
                      aria-label="Learn"
                      key={"Learn"}>
                <ListItemIcon id="learn-list-icon">
                    <SchoolIcon id="learn-icon"/>
                </ListItemIcon>
                <ListItemText id="learn-icon-text"  primary={"Learn"}/>
            </ListItem>
            <Divider/>
            { currentUser &&
                <ListItem id="wards-list"
                          button
                          component={Link}
                          to={"/wards"}
                          aria-label="My Wards"
                          key={"My Wards"}>
                    <ListItemIcon id="wards-list-icon">
                        <KeyIcon id="wards-icon"/>
                    </ListItemIcon>
                    <ListItemText id="wards-icon-text"  primary={"My Wards"}/>
                </ListItem>
            }
            <ListItem id="account-list"
                      button
                      component={Link}
                      to={"/account"}
                      aria-label="My Account"
                      key={"My Account"}>
                <ListItemIcon id="account-list-icon">
                    <AccountCircleIcon id="account-icon"/>
                </ListItemIcon>
                <ListItemText id="account-icon-text" primary={"My Account"}/>
            </ListItem>
            <Divider/>
            {signOutButton()}
        </List>);


    return (<React.Fragment key={menuDrawerAnchor}>
            <IconButton
                id="menu-icon-button"
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu button"
                onClick={toggleDrawer(menuDrawerAnchor, true)}
            >
                <MenuIcon id="menu-icon"/>
            </IconButton>
            <nav id="menu-nav">
                <Drawer
                    style={{
                        borderRadius: 'initial',
                        padding: 'initial',
                        width: 'initial',
                        maxWidth: 'initial',
                        maxHeight: 'initial',
                        overflowY: 'initial',
                        backgroundColor: 'initial',
                        opacity: 'initial',
                        boxShadow: 'initial',
                        position: 'fixed',  // The Drawer should be fixed to behave correctly
                        top: '0',           // Drawer starts at the top
                        left: '0',          // Drawer starts at the left
                        transform: 'none',  // No translation needed for Drawer
                        animation: 'none'   // Remove any animations for now
                    }}
                    id="menu-drawer"
                    anchor={menuDrawerAnchor}
                    open={navOpen[menuDrawerAnchor]}
                    aria-label={"menu display " + menuDrawerAnchor}
                    onClose={toggleDrawer(menuDrawerAnchor, false)}
                >
                    {list(menuDrawerAnchor)}
                </Drawer>
            </nav>
        </React.Fragment>);
}
