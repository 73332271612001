import React from 'react';
import {Grid, Card, CardContent, Button, CardMedia, useMediaQuery, useTheme } from "@mui/material";
import ReactMarkdown from 'react-markdown';

export const ContentCardR = ({ title, description, buttonData, image }) => {
    const theme = useTheme();
    const isXLScreen = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <Grid
            item
            justifyContent="center"// Center the card on all screen sizes
            style={{
                width: isXLScreen ? '70%' : '100%', // 70% width on XL screens, full width otherwise
                marginLeft: isXLScreen ? '5%' : 'auto', // Small margin on the left on XL screens for spacing
                marginRight: isXLScreen ? 'auto' : '0', // Auto margin on the right on XL screens for centering
            }}
        >
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
            >
                <Card>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <CardMedia
                                component="img"
                                image={image}
                                style={{
                                    maxHeight: '70%',
                                    maxWidth: '70%',
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column', // To stack children vertically
                            }}
                        >
                            <CardContent style={{
                                width: '100%', // To use full width of the grid item
                                textAlign: 'center' // For centering text elements
                            }}>
                                <ReactMarkdown>{title}</ReactMarkdown>
                                <ReactMarkdown>{description}</ReactMarkdown>
                                {buttonData && <Button href={buttonData.url} variant="contained">{buttonData.text}</Button>}
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};
